<template>
  <master-layout :showContentHeader="false">
    <ion-grid>
      <!-- <ion-row>
        <ion-col>
          <h5>Seleziona chi vuoi chiamare</h5>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <swiper
            :effect="'coverflow'"
            :centeredSlides="true"
            :slidesPerView="'auto'"
            :coverflowEffect="{
              rotate: 0,
              stretch: 0,
              depth: 72,
              modifier: 2,
              slideShadows: false,
            }"
            class="my-swiper"
          >
            <swiper-slide
              part="swiper-slide"
              v-for="user in users"
              :key="user.id"
              class="slide"
              :class="{ selected: user.selected }"
              @click="toggleSelected(user)"
            >
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <ion-avatar>
                      <ion-img v-if="user.avatar_url" :src="user.avatar_url" />
                      <ion-icon v-else :icon="personCircleOutline"></ion-icon>
                    </ion-avatar>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </swiper-slide>
          </swiper>
        </ion-col>
      </ion-row>
      <ion-row v-if="showCalendar">
        <ion-col>
          <ion-row>
            <ion-col>
              <h5>
                Quando vuoi chiamare {{ currentUser.nome }}
                {{ currentUser.cognome }}?
              </h5>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-datetime
                :show-default-time-label="true"
                presentation="time-date"
                :min="today"
                v-model="datetime"
              >
              </ion-datetime>
              <ion-button @click="confirmCall">Conferma</ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row> -->
      <ion-row>
        <ion-col>
          <h5>I tuoi prossimi incontri</h5>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12">
          <v-calendar
            is-expanded
            :attributes="attributes"
            :min-date="new Date()"
            :max-date="new Date()"
            :disabled-dates="{}"
          ></v-calendar>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <task-component
            v-for="task in items"
            :key="task.id"
            :id="task.id"
            :img="task.img"
            :title="task.title"
            :description="task.description"
            :date="task.dates.date.slice(0, 10)"
            :isDone="task.isDone"
            :comment="task.comment"
          ></task-component>
        </ion-col>
      </ion-row>
    </ion-grid>
  </master-layout>
</template>

<script>
import TaskComponent from "../components/TaskComponent.vue"
import {
  IonIcon,
  IonButton,
  IonCard,
  IonImg,
  IonAvatar,
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonicSwiper,
} from "@ionic/vue"

import { personCircleOutline } from "ionicons/icons"

import ApiService from "../common/service.api"
import { translate } from "../common/helper.auth"

import "swiper/swiper-bundle.min.css"
import "swiper/components/pagination/pagination.scss"
import "@ionic/vue/css/ionic-swiper.css"

import SwiperCore, { Navigation, EffectCoverflow, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/vue"

SwiperCore.use([IonicSwiper, Navigation, EffectCoverflow, Pagination])

export default {
  name: "InductionPlan",
  components: {
    IonIcon,
    IonButton,
    IonCard,
    IonImg,
    IonAvatar,
    IonGrid,
    IonRow,
    IonCol,
    IonDatetime,
    TaskComponent,
    Swiper,
    SwiperSlide,
  },
  created() {
    this.today = new Date().toISOString()
    console.log(this.whappyClientConfig.secondaryColor)
    // ApiService.get(`User`).then((res) => {
    //   res.data.forEach((element) => {
    //     element.selected = false;
    //     this.users.push(element);
    //   });
    //   console.log(this.users);
    // });
  },
  data() {
    return {
      today: "",
      // users: [],
      currentUser: {},
      showCalendar: false,
      datetime: "",
      items: [
        {
          id: 42,
          img: "https://api-people.whappy.it/Drive/03b6427f-6b85-4de6-9d7f-b0c8284af0bf_image.jpg",
          title: "Raniero Amati",
          description: "call di inserimento",
          isComplete: false,
          dates: { date: "2022-02-02T03:00:00.000Z" },
          color: this.whappyClientConfig.primaryColor,
        },
        {
          id: 14,
          img: "https://api-whappy.arkadiagroup.it/Drive/9d1879ad-0478-457c-b36f-40f8de1f0ba2_40.jpg",
          title: "Michele Castellani",
          description: "call di inserimento",
          isComplete: false,
          dates: { date: "2022-02-03T03:00:00.000Z" },
          color: this.whappyClientConfig.primaryColor,
        },
        {
          id: 41,
          img: "https://api-whappy.arkadiagroup.it/Drive/b23217a7-f62d-46fc-b71f-56ee51b7873b_1626086395843.jpg",
          title: "Claudio Oliva",
          description: "call di inserimento",
          isComplete: false,
          dates: { date: "2022-02-06T03:00:00.000Z" },
          color: this.whappyClientConfig.primaryColor,
        },
        {
          id: 39,
          img: "https://api-people.whappy.it/Drive/d189b184-ee7c-465a-beb1-68df3b2308e3_image.jpg",
          title: "Stefano De Santis",
          description: "call di inserimento",
          isComplete: false,
          dates: { date: "2022-02-23T03:00:00.000Z" },
          color: this.whappyClientConfig.primaryColor,
        },
      ],
      //icons
      personCircleOutline,
    }
  },
  computed: {
    attributes() {
      return [
        ...this.items.map((item) => ({
          dates: item.dates.date,
          dot: {
            backgroundColor: item.color,
            opacity: item.isComplete ? 0.3 : 1,
          },
          popover: {
            label:
              item.title +
              " - " +
              item.description.charAt(0).toUpperCase() +
              item.description.slice(1),
          },
        })),
      ]
    },
  },
  methods: {
    // toggleSelected(item) {
    //   this.showCalendar = true;
    //   if (this.currentUser) {
    //     this.currentUser.selected = false;
    //     this.currentUser = item;
    //   } else {
    //     this.currentUser = item;
    //   }
    //   item.selected = !item.selected;
    //   this.currentUser.description = "Call di inserimento";
    //   this.currentUser.title = item.nome + " " + item.cognome;
    // },
    // confirmCall() {
    //   console.log(this.datetime);
    //   console.log(this.datetime.slice(0, 10));
    //   let nextCall = {
    //     id: this.currentUser.id,
    //     img: this.currentUser.avatar_url,
    //     title: this.currentUser.title,
    //     description: "Call di inserimento",
    //     date: this.datetime.slice(0, 10) + " " + this.datetime.slice(11, 16),
    //   };
    //   this.items.push(nextCall);
    // },
  },
}
</script>

<style scoped>
/* * {
  border: 1px solid red;
} */

.my-swiper {
  padding-top: 1rem;
  height: 15vh;
}

.slide {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  filter: grayscale(100%);
}

.selected {
  filter: grayscale(0%);
}

.selected ion-avatar {
  border: 5px solid var(--ion-color-primary);
}

.selected ion-icon {
  top: -3px;
  left: -3px;
}

ion-avatar {
  position: absolute;
  top: -5px;
  left: -5px;
}

ion-icon {
  position: absolute;
  top: 0px;
  font-size: 3.8rem;
}
</style>
